import {
  CONTACT_RECEIVE,
  CONTACT_REQUEST,
  CONTACT_FAILURE,
  CONTACT_OFFICIAL_REQUEST,
  CONTACT_OFFICIAL_RECEIVE,
  CONTACT_OFFICIAL_FAILURE,
  PAGE_DATA_LOAD,
} from '../action-types';
import PageDataLoader from '../../lib/page-data/loader';
import PageKey from '../../lib/page-data/key';

export const fetchContacts = (clubId, request) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_REQUEST, payload: { clubId } });
    const pageKey = new PageKey(clubId, 'contact');
    const pageData = await PageDataLoader.getPageData(pageKey, request);
    const { locations, officialGroups, otherOfficials } = pageData.data;
    dispatch({
      type: CONTACT_RECEIVE,
      payload: { clubId, locations, officialGroups, otherOfficials },
    });
    dispatch({ type: PAGE_DATA_LOAD, payload: pageKey.toString() });
    return {};
  } catch (error) {
    let statusCode = 500;
    if (error) {
      statusCode = error.errorStatusCode || statusCode;
    }
    dispatch({
      type: CONTACT_FAILURE,
      payload: { clubId, statusCode, error },
    });
    return { error };
  }
};

export const contactOfficial =
  (clubId, officialId, formData, onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({ type: CONTACT_OFFICIAL_REQUEST, payload: { officialId, clubId } });
      const { makeIsomorphicApiRequest } = await import('../../lib/api-proxy');
      const response = await makeIsomorphicApiRequest(
        `v2/club-website/${clubId}/contact/${officialId}`,
        {
          method: 'POST',
          body: JSON.stringify(formData),
        },
      );

      if (response.status !== 200) {
        dispatch({ type: CONTACT_OFFICIAL_FAILURE });
        if (typeof onError === 'function') onError();
        return;
      }

      await response.json();
      dispatch({ type: CONTACT_OFFICIAL_RECEIVE });
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      dispatch({ type: CONTACT_OFFICIAL_FAILURE });
      if (typeof onError === 'function') onError();
    }
  };
