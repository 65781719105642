import React from 'react';
import {
  Avatar,
  Cushion,
  Grid,
  Input,
  LinearGradient,
  TextArea,
  Typography,
} from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import { Trans } from '../../lib/i18n';

const OfficialContactForm = ({
  validationErrors,
  isSending,
  updateValues,
  values,
  official,
  submissionFailed,
}) => (
  <Grid columns="auto">
    <LinearGradient
      component="div"
      angle={0}
      stops={[
        [0, 'white'],
        ['50%', 'white'],
        ['50%', 'primary'],
      ]}
    >
      <Grid>
        <Avatar
          image={official.avatar}
          includeRetina
          alt={official.name}
          boxShadow="dark"
          size={66}
          style={{ justifySelf: 'center' }}
        />
      </Grid>
    </LinearGradient>
    <Cushion top="medium">
      <Typography preset="title" component="div" align="center">
        {official.name}
      </Typography>
    </Cushion>
    <Cushion top="small">
      <Typography preset="subtitle--small" color="dovegrey" align="center" component="div">
        {official.position}
      </Typography>
    </Cushion>
    {official.mobile && (
      <Cushion top="small">
        <Typography preset="body--small" color="dustygrey" align="center" component="div">
          <Trans i18nKey="common:mobile" values={{ number: official.mobile }}>
            Mobile:
            {official.mobile}
          </Trans>
        </Typography>
      </Cushion>
    )}

    <Cushion all="medium">
      <Cushion bottom="medium">
        <Cushion bottom="xsmall">
          <Typography preset="description" component="label" htmlFor="name" color="dovegrey">
            <Trans i18nKey="common:name_label">Your name</Trans>
          </Typography>
        </Cushion>
        <Input
          input={{ type: 'text', value: values.name }}
          name="name"
          disabled={isSending}
          meta={{
            touched: true,
            error: validationErrors.name,
          }}
          onChange={(event) => updateValues({ ...values, name: event.target.value })}
        />
      </Cushion>
      <Cushion bottom="medium">
        <Cushion bottom="small">
          <Typography preset="description" component="label" htmlFor="email" color="dovegrey">
            <Trans i18nKey="common:email_label">Your email</Trans>
          </Typography>
        </Cushion>
        <Input
          input={{ type: 'email', value: values.email }}
          name="email"
          disabled={isSending}
          meta={{
            touched: true,
            error: validationErrors.email,
          }}
          onChange={(event) => updateValues({ ...values, email: event.target.value })}
        />
      </Cushion>
      <Cushion bottom="medium">
        <Cushion bottom="small">
          <Typography preset="description" component="label" htmlFor="phone" color="dovegrey">
            <Trans i18nKey="common:phone_label">Your phone</Trans>
          </Typography>
        </Cushion>
        <Input
          input={{ type: 'text', value: values.phone }}
          name="phone"
          disabled={isSending}
          meta={{
            touched: true,
            error: validationErrors.phone,
          }}
          onChange={(event) => updateValues({ ...values, phone: event.target.value })}
        />
      </Cushion>
      <Cushion bottom="medium">
        <Cushion bottom="small">
          <Typography preset="description" component="label" htmlFor="message" color="dovegrey">
            <Trans i18nKey="common:message_label">Your message</Trans>
          </Typography>
        </Cushion>
        <TextArea
          input={{ type: 'textarea', value: values.message }}
          name="message"
          disabled={isSending}
          meta={{
            touched: true,
            error: validationErrors.message,
          }}
          onChange={(event) => updateValues({ ...values, message: event.target.value })}
          rows={10}
        />
      </Cushion>
      <Grid>
        <Cushion bottom="xxxxlarge">
          {submissionFailed && (
            <Typography preset="description" component="label" color="negative">
              <Trans i18nKey="common:contact_official_submission_error">submission failed</Trans>
            </Typography>
          )}
        </Cushion>
      </Grid>
    </Cushion>
  </Grid>
);

OfficialContactForm.propTypes = {
  isSending: PropTypes.bool.isRequired,
  official: PropTypes.shape().isRequired,
  submissionFailed: PropTypes.bool.isRequired,
  updateValues: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
};

export default OfficialContactForm;
