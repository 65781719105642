import {
  Cushion,
  Grid,
  LinearGradient,
  Shadow,
  ShimmerAvatar,
  ShimmerTypography,
} from '@pitchero/react-ui';
import React from 'react';

const OfficialContactFormPlaceholder = () => (
  <Grid columns="auto" style={{ position: 'relative' }}>
    <LinearGradient
      component="div"
      angle={0}
      stops={[
        [0, 'white'],
        ['50%', 'white'],
        ['50%', 'primary'],
      ]}
    >
      <Grid>
        <Cushion style={{ justifySelf: 'center' }} component="div">
          <Shadow>
            <ShimmerAvatar size={66} />
          </Shadow>
        </Cushion>
      </Grid>
    </LinearGradient>
    <Cushion top="medium" component="div" horizontal="xxlarge">
      <ShimmerTypography preset="title" />
    </Cushion>
    <Cushion top="small" horizontal="medium" component="div">
      <ShimmerTypography preset="subtitle--small" />
    </Cushion>
    <Cushion horizontal="large" component="div">
      <ShimmerTypography preset="subtitle--small" />
    </Cushion>
  </Grid>
);

export default OfficialContactFormPlaceholder;
