import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Cushion, Panel, Rectangle, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import { isAttemptingToContactOfficial } from '../../store/contact/selectors';
import { Trans } from '../../lib/i18n';
import LoadingBar from '../loading-bar';
import { contactOfficial } from '../../store/contact/actions';
import { getLoggedInUser } from '../../store/membership/selectors';
import OfficialContactForm from './official-contact-form';
import OfficialContactFormPlaceholder from './official-contact-form-placeholder';
import CompleteOfficialContact from './complete-official-contact';
import validate from './form-validate';

const ContactOfficialPanel = ({
  isOpen,
  official,
  club,
  isContactingOfficial,
  contactAction,
  onClose,
  user,
}) => {
  const initialFormData = {
    name: user ? user.name : '',
    email: '',
    phone: '',
    messsage: '',
  };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [messageSent, setMessageSent] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [hasSubmissionError, setHasSubmissionError] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const onCloseHandler = () => {
    setValidationErrors({});
    setHasSubmissionError(false);
    setFormData(initialFormData);
    setMessageSent(false);
    onClose();
  };

  const onSendMessageClickHandler = async () => {
    // Clear any previous submission error
    setHasSubmissionError(false);

    // Check for Validation errors
    const errors = validate(formData);
    if (!isEmpty(errors)) {
      setValidationErrors(errors);
      return;
    }
    // Form is valid, clear any previous validation errors
    setValidationErrors({});

    // Fetch token from Recaptcha
    const recaptchaToken = await executeRecaptcha('contactOfficial');

    contactAction(
      club.id,
      official.id,
      { ...formData, recaptcha: recaptchaToken },
      () => {
        // if successful mark message as sent so view can switch
        setMessageSent(true);
      },
      () => {
        // if something goes wrong set submission error
        setHasSubmissionError(true);
      },
    );
  };

  const submitButton = () => {
    if (!messageSent) {
      return (
        <Cushion all="medium" component="div">
          <Button
            fullWidth
            theme="primary"
            onClick={onSendMessageClickHandler}
            disabled={isContactingOfficial}
          >
            <Typography preset="name">
              <Trans i18nKey="common:send_message_button_text">Send message</Trans>
            </Typography>
          </Button>
        </Cushion>
      );
    }
    return null;
  };

  const content = () => {
    if (messageSent) {
      return <CompleteOfficialContact />;
    }
    if (official) {
      return (
        <OfficialContactForm
          isSending={isContactingOfficial}
          official={official}
          submissionFailed={hasSubmissionError}
          updateValues={setFormData}
          validationErrors={validationErrors}
          values={formData}
        />
      );
    }
    return <OfficialContactFormPlaceholder />;
  };

  return (
    <Panel isOpen={isOpen} onClose={onCloseHandler} renderActions={submitButton}>
      <Rectangle fill="white" style={{ height: '100%' }}>
        <div style={{ overflowY: 'auto' }}>
          <Rectangle fill="primary">
            <LoadingBar isLoading={isContactingOfficial} />
            <Cushion top="medium" bottom="small">
              <Typography preset="title" color="white" component="div" align="center">
                <Trans i18key="common:contact">Contact</Trans>
              </Typography>
            </Cushion>
          </Rectangle>
          {content()}
        </div>
      </Rectangle>
    </Panel>
  );
};

ContactOfficialPanel.defaultProps = {
  official: false,
  isContactingOfficial: false,
  user: null,
};

ContactOfficialPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  official: PropTypes.shape(),
  club: PropTypes.shape().isRequired,
  isContactingOfficial: PropTypes.bool,
  contactAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  isContactingOfficial: isAttemptingToContactOfficial(state),
  user: getLoggedInUser(state),
});

export default connect(mapStateToProps, { contactAction: contactOfficial })(ContactOfficialPanel);
