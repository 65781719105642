import React from 'react';
import { Avatar, Shadow, Typography, Cushion, Rectangle, FlexContainer } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import { Trans } from '../../lib/i18n';
import ClubLink from '../club-link';

const Official = ({ official, onClickEmail }) => {
  const clickTel = (e) => {
    e.preventDefault();
    window.location.href = e.target.attributes.href.value;
  };

  const hasContact = official.phone || official.mobile || official.canEmail;

  const content = () => (
    <Shadow>
      <Rectangle fill="white" radius="rounded">
        <Cushion all="medium">
          <FlexContainer flexDirection="row" alignItems="center">
            <Avatar
              image={official.avatar}
              includeRetina
              radius="circle"
              alt={official.name}
              size={60}
            />
            <Cushion left="medium">
              <FlexContainer flexDirection="column" style={{ flex: 1 }}>
                <Typography component="div" preset="subtitle--small" color="black">
                  {official.role}
                </Typography>
                <Typography component="div" preset="body--small" color="dustygrey">
                  {official.name}
                </Typography>
                {hasContact && (
                  <FlexContainer
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ justifySelf: 'end' }}
                  >
                    <div>
                      {official.phone && (
                        <Typography
                          component="div"
                          preset="body--small"
                          color="black"
                          href={`tel:${official.phone}`}
                          onClick={clickTel}
                        >
                          {official.phone}
                        </Typography>
                      )}
                      {official.mobile && (
                        <Typography
                          component="div"
                          preset="body--small"
                          color="black"
                          href={`tel:${official.mobile}`}
                          onClick={clickTel}
                        >
                          {official.mobile}
                        </Typography>
                      )}
                    </div>
                    {official.canEmail && (
                      <Typography
                        className="contact-official-link"
                        color="primary"
                        preset="button"
                        component="div"
                        align="right"
                        style={{ cursor: 'pointer' }}
                      >
                        <Trans i18key="common:email" component="div" style={{ cursor: 'pointer' }}>
                          Email
                        </Trans>
                      </Typography>
                    )}
                  </FlexContainer>
                )}
              </FlexContainer>
            </Cushion>
          </FlexContainer>
        </Cushion>
      </Rectangle>
    </Shadow>
  );

  const wrapWithLink = () => {
    if (onClickEmail) {
      return (
        <div
          onClick={() => onClickEmail(official)}
          onKeyDown={() => onClickEmail(official)}
          aria-label={`Contact ${official.name}`}
          role="button"
          tabIndex={-1}
        >
          {content()}
        </div>
      );
    }

    return (
      <ClubLink
        route="contact.official"
        params={{ officialId: official.id }}
        replace
        scroll={false}
      >
        {content()}
      </ClubLink>
    );
  };

  return official.canEmail ? wrapWithLink() : content();
};

Official.defaultProps = {
  onClickEmail: null,
};

Official.propTypes = {
  official: PropTypes.shape().isRequired,
  onClickEmail: PropTypes.func,
};

export default Official;
